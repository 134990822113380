"use client";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { usePromotionStore } from "@/stores/promotionStore";
import { shallow } from "zustand/shallow";
import usePromotionList from "@/hooks/fetchers/usePromotionList";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Grid,
  TextField,
  CircularProgress,
  Stack,
  Snackbar,
  Divider,
  Chip,
  alpha,
  Tabs,
  Tab,
  useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMe from "@/hooks/fetchers/useMe";
import { useAppModalStore } from "@/stores/appModalStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { Icon } from "@iconify/react";
import { useConfigStore } from "@/stores/configStore";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const menus: any[] = [
  {
    key: "all",
    label: "ทั้งหมด",
    icon: "material-symbols:border-all-rounded",
  },
  {
    key: "firstdeposit",
    label: "ซิ่งแรก",
    match: 'แรก',
    icon: 'material-symbols:bolt'
  },
  {
    key: "daily",
    label: "ซิ่งกระจาย",
    match: 'กระจาย',
    icon: 'material-symbols:local-fire-department'
  },
  // {
  //   key: "sport",
  //   label: "กีฬา",
  //   icon: "emojione-v1:soccer-ball",
  // },
  // {
  //   key: "lottery",
  //   label: "ล็อตเตอรี่",
  //   icon: "emojione-v1:pool-8-ball",
  // },
  // {
  //   key: "fish",
  //   label: "ยิงปลา",
  //   icon: "logos:glitch-icon",
  // },
];

export default function PromotionsList() {
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [accept] = usePromotionStore((state) => [state.accept], shallow);
  const { data: promotionList, isLoading: isPromotionListLoading } =
    usePromotionList();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [promotionid, setPromotionId] = useState("");
  const [promotionname, setPromotionName] = useState("");
  const [promotiondesc, setPromotionDesc] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [setAppModalOpen] = useAppModalStore(
    (state) => [state.setOpen],
    shallow
  );

  function showDialog(id: string) {
    var options: any = {
      id: `${id}`,
    };

    var filtered = promotionList?.filter(function (obj: any) {
      return Object.keys(options).some(function (key: any) {
        if (key != "color") {
          return obj[key] == options[key];
        } else {
          return options[key].some(function (s: any) {
            return s == obj[key];
          });
        }
      });
    });

    if (filtered != undefined) {
      setPromotionId(filtered[0].id);
      setPromotionName(filtered[0].name);
      setPromotionDesc(filtered[0].description);
      setOpen(true);
    }
  }

  function acceptPromotion(id: string) {
    setIsLoading(true)
    accept(promotionid || id)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          setIsLoading(false)
        } else {
          enqueueSnackbar("รับโปรโมชั่นสำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          refresh();
          refreshPromotion();
          setAppModalOpen(false);
          setIsLoading(false)
          handleClose();
        }
      })
      .catch((err) => {
        enqueueSnackbar("This is a success message!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setIsLoading(false)
      });
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (_value: any, index: number) => {
    setCategory(index);
  };

  const filterMenus = config?.agent_name === 'wisdom168'
    ? menus.filter(menu => menu.key !== 'lottery')
    : menus

  if (isPromotionListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box width={'100%'}>
        <Box sx={{
          flexGrow: 1,
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: 'start',
                    md: 'start',
                    lg: 'start'
                  },
                }}
              >
                <Tabs
                  value={category}
                  onChange={handleMenuClick}
                  variant="scrollable"
                  aria-label="game menu"
                  indicatorColor={"none" as any}
                  scrollButtons={false}
                  // scrollButtons="auto"
                  sx={{
                    height: '54px',
                    minHeight: '54px',
                    // background: theme.gradient[100],
                    // borderRadius: '2rem',
                    alignItems: 'center'
                  }}
                >
                  {
                    menus
                      .filter(menu => {
                        return promotionList?.find(promotion => menu?.label === 'ทั้งหมด' || promotion?.name.includes(menu?.label))
                      })
                      .map((menu, index) => (
                        <Tab
                          icon={
                            <Icon
                              id="icon"
                              icon={menu?.icon || ""}
                              width={24}
                              height={24}
                            />
                          }
                          iconPosition="start"
                          key={menu.key}
                          label={menu.label}
                          sx={{
                            color: category === index
                              ? theme.name === 'preset28'
                                ? '#fff'
                                : theme.name === 'preset16'
                                  ? `${theme.gradient[100]} !important`
                                  : (theme.gradient && theme.gradient[920])
                                    ? `${theme.gradient[920]} !important`
                                    : `${theme.palette.secondary.main} !important`
                              : (theme.gradient && theme.gradient[920])
                                ? theme.gradient[920]
                                : theme.palette.primary.main,
                            background: category === index
                              ? theme.name === 'preset16'
                                ? theme.palette.primary.main
                                : '#191d23'
                              : theme.name === 'preset28'
                                ? '#010101'
                                : theme.name === 'preset16'
                                  ? 'transparent'
                                  : 'transparent',
                            height: '44px',
                            minHeight: '44px',
                            borderRadius: '2rem',
                            border: category === index
                              ? theme.name === 'preset16'
                                ? 'initial'
                                : 'initial'
                              : theme.name === 'preset16'
                                ? `1px solid ${theme.palette.primary.main}`
                                : `1px solid rgba(255, 255, 255, 0.5)`,
                            margin: index === 0
                              ? '0 0 0 0'
                              : filterMenus.length === index + 1
                                ? '0 8px 0 8px'
                                : '0 0 0 8px',

                            // borderRadius: index === 0
                            //   ? '1rem 0 0 1rem'
                            //   : menus.length === index + 1
                            //     ? '0 1rem 1rem 0'
                            //     : 'initial',
                            // ...(isMobile && {
                            //   minWidth: "60px",
                            // }),
                          }}
                        />
                      ))}
                </Tabs>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Grid
          mt={0}
          container
          rowSpacing={3}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          {promotionList?.filter(promotion => {
            return category === 0
              ? promotion
              : promotion?.name.includes(menus[promotionList?.find(promotion => promotion?.name.includes('แรก')) ? category : category + 1]['match'])
          })?.map((item: any) => (
            <Grid item xs={1} sm={4} md={4}>

              <Card sx={{ color: (theme) => theme.gradient[900] }}>
                {/* <CardContent>
                  <CardMedia component="img" image={item?.img} alt={item?.name} />
                </CardContent> */}
                <CardContent>
                  <Box textAlign="center">
                    <Box width={'100%'} display={'flex'} justifyContent={'start'}>
                      <Chip
                        label={
                          item?.deposit_min !== '0.00'
                            ? item?.name.includes('แรก') ? 'ฝากแรก' : 'ไม่จำกัด'
                            : 'เครดิตฟรี'
                        }
                        variant="outlined"
                        sx={{ mr: 0.5 }}
                      />
                      {
                        item?.name.includes('แรก') && (
                          <Chip label={'รับได้ครั้งเดียว'} variant="outlined" />
                        )
                      }
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Typography gutterBottom sx={{
                      fontSize: 18,
                      fontWeight: 700,
                    }}>
                      {item?.name}
                    </Typography>
                    {
                      item?.deposit_min !== '0.00' && (
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                          <Typography gutterBottom sx={{
                            fontSize: 18,
                          }}>
                            ฝากเงิน:
                          </Typography>
                          <Typography gutterBottom sx={{
                            fontSize: 18,
                            fontWeight: 700,
                          }}>
                            {item?.deposit_min}
                          </Typography>
                        </Box>
                      )
                    }
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                      }}>
                        รับเครดิต:
                      </Typography>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}>
                        {item?.plus_percent}
                      </Typography>
                    </Box>
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                      }}>
                        ทำยอด:
                      </Typography>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}>
                        {item?.withdraw_min}
                      </Typography>
                    </Box>
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                      }}>
                        ถอนเงิน:
                      </Typography>
                      <Typography gutterBottom sx={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}>
                        {item?.withdraw_max}
                      </Typography>
                    </Box>
                    <Button
                      disabled={isLoading}
                      fullWidth
                      variant="contained"
                      onClick={() => acceptPromotion(item?.id)}
                    >
                      {item?.deposit_min !== '0.00' ? 'สมัคร' : 'สมัครฟรี'}
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => showDialog(item?.id)}
                      sx={{ mt: 1 }}
                    >
                      รายละเอียด
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2, color: (theme) => theme.gradient[900] }} id="customized-dialog-title">
              {promotionname}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ p: '0 16px !important' }}>
              <Typography
                gutterBottom
                sx={{ color: (theme) => theme.gradient[900] }}
              >
                <p dangerouslySetInnerHTML={{ __html: promotiondesc }}></p>
              </Typography>
            </DialogContent>
            <DialogActions>
              {/* <Button
                variant="contained"
                autoFocus
                onClick={() => acceptPromotion()}
              >
                รับโปรโมชั่น
              </Button> */}
              <Button
                variant="outlined"
                // autoFocus
                onClick={() => handleClose()}
              >
                ปิด
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </Grid>
      </Box>
    );
  }
}
