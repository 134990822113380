"use client";

import * as React from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import SignInForm from "@/sections/Login";
import { getUserServerSide } from "@/libs/auth";
import { redirect } from "next/navigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { shallow } from "zustand/shallow";
import { useSnackbar } from "notistack";
// import WinWheel from "@/components/WinWheel/wheel";
import { useLuckyWheelStore } from "@/stores/luckyWheelStore";
import useMysteryBoxList from "@/hooks/fetchers/useMysteryBoxList";
import useMysteryBoxHistory from "@/hooks/fetchers/useMysteryBoxHistory";
import MysteryBox from "./MysteryBox";
import "./style.css";
import { useConfigStore } from "@/stores/configStore";
import { useAuthStore } from "@/stores/authStore";
import numeral from "numeral";

const TYPES: {
  [key: string]: string;
} = {
  wheel_ticket: "ตั๋ว",
  wheel_credit: "ซื้อ",
  mystery_box: "ตั๋ว",
};

const MysteryBoxContent = () => {
  // const user = await getUserServerSide();
  // if (!user) {
  //   redirect("/login");
  // }

  const [user] = useAuthStore((state) => [state.user], shallow);
  const { data: wheels, isLoading: isWheelListLoading, sum_all } = useMysteryBoxList();
  const { data: histories, isLoading: isWheelHistoryLoading } =
    useMysteryBoxHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [config] = useConfigStore((state) => [state.config], shallow);

  function createData(name: string, calories: string) {
    return { name, calories };
  }

  const rows = [createData(config?.mystery_box_max_winloss || "0", "1 ครั้ง")];

  return (
    <Box width={'100%'}>
      <Box width={'100%'}>

        <Box display="flex" flexDirection={"row"} alignItems={"start"} justifyContent={'space-between'} mt={3}>
          <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
            {`จำนวนตั๋วปัจจุบัน: `}
            <span style={{ color: user?.wheel_ticket ? '#3DFB35' : '#fdbd00' }}>{`${user?.mystery_box_ticket ? 1 : 0}`}</span>
          </Typography>
          {/* <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }} onClick={() => { setDialogOpen(true) }}>
              <span style={{ color: '#fdbd00', cursor: 'pointer' }}>รายละเอียด</span>
            </Typography> */}
        </Box>

        <Box display="flex" flexDirection={"column"} alignItems={"start"} mt={1}>
          <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
            {`ยอดเล่นเพื่อแลกตั๋ว: `}
            <span
              style={{
                color: config?.mystery_box_max_turnover && sum_all >= parseFloat(config.mystery_box_max_turnover)
                  ? '#3DFB35'
                  : '#fdbd00'
              }}
            >
              {`${sum_all ? numeral(sum_all).format("0,0.00") : '0'}`}
            </span>
            <span>{`/${config?.mystery_box_max_turnover ? numeral(config?.mystery_box_max_turnover).format("0,0.00") : '0'}`}</span>
          </Typography>
        </Box>

        <Box mt={2}>
          <Divider sx={{ background: '#213743', borderWidth: '1px' }} />
        </Box>

        <Box mt={2}>
          <MysteryBox />
        </Box>

        <Box mt={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={(theme) => theme.gradient[900]}>ประวัติการรับโบนัส</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                    {histories
                      ? histories.slice(0, 5).map((row: any) => (
                        <TableRow
                          key={row.created_at}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                            {row.created_at}
                          </TableCell>
                          <TableCell align="right" sx={{ color: '#fdbd00' }}>{row.credit}</TableCell>
                        </TableRow>
                      ))
                      : histories}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default MysteryBoxContent;
