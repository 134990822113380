"use client";

import React, { CSSProperties } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import "./style.css";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import Marquee from "../../components/carousel/Marquee";
import { useAppModalStore } from "@/stores/appModalStore";

type Props = {};

const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 32,
  height: 4,
  display: "inline-block",
  margin: "0 8px",
};

export default function BannerCarousel({ }: Props) {
  const theme = useTheme();
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  const { data: bannersliders, isLoading: isBannerSliderLoading } =
    useBannerSlider();

  if (isBannerSliderLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ mt: 2 }}>
        {/* <Marquee /> */}
        <Carousel
          interval={isMobile ? 0 : 6000}
          infiniteLoop={isMobile ? false : true}
          autoPlay={isMobile ? false : true}
          showArrows={false}
          centerMode
          showStatus={false}
          showThumbs={false}
          centerSlidePercentage={isMobile ? 92.5 : 100}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          stopOnHover={false}
          showIndicators={isMobile ? false : true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{
                    ...indicatorStyles,
                    borderRadius: theme.shape.borderRadius,
                    background: theme.palette.primary.main,
                    width: 8,
                    height: 8,
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }

            return (
              <li
                style={{
                  ...indicatorStyles,
                  borderRadius: theme.shape.borderRadius,
                  background: '#b7b8ba',
                  width: 8,
                  height: 8
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {[
            {
              image: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/z4_ba_1.jpg',
              desc: "ออนไลน์คาสิโนสมัยใหม่"
            },
            {
              image: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/z4_ba_4.jpg',
              desc: "กล่องสุ่มรับเงิน"
            },
            {
              image: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/z4_ba_5.jpg',
              desc: "หมุนวงล้อลุ้นรางวัล"
            },
            {
              image: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/z4_ba_6.jpg',
              desc: "คืนยอดเสีย"
            },
          ]?.map((banner) => (
            <Box
              key={banner?.desc}
              mx={{
                xs: 0,
                md: 1,
              }}
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => {
                if (!isLoggedIn) {
                  return
                } else {
                  switch (banner.desc) {
                    case 'ออนไลน์คาสิโนสมัยใหม่':
                      setData('deposit');
                      setOpenAppModal(true);
                      return;

                    case "กล่องสุ่มรับเงิน":
                      setData('mysteryBox');
                      setOpenAppModal(true);
                      return;

                    case "หมุนวงล้อลุ้นรางวัล":
                      setData('wheel');
                      setOpenAppModal(true);
                      return;

                    case 'คืนยอดเสีย':
                      setData('refund');
                      setOpenAppModal(true);
                      return;

                    default:
                      return;
                  }
                }
              }}
            >
              <img
                style={{
                  width: isMobile ? '97.5%' : '100%',
                  borderRadius: "16px",
                }}
                src={banner?.image}
              />
            </Box>
          ))}
        </Carousel>
      </Box>
    );
  }
}
