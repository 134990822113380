import React, { useEffect } from "react";
import MysteryBoxItem from "./MysteryBoxItem";
import { useMysteryBoxStore } from "@/stores/mysteryBoxStore";
import { shallow } from "zustand/shallow";
import { shuffleLuckyBoxResult } from "@/libs/utils";
import { openMysteryBox } from "@/services/mysteryBoxService";
import { useSnackbar } from "notistack";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import useMysteryBoxList, {
  MysteryBoxList,
} from "@/hooks/fetchers/useMysteryBoxList";
import { mutate } from "swr";
import useMe from "@/hooks/fetchers/useMe";

interface IProps { }

export default function MysteryBox({ }: IProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { refresh } = useMe();
  const [
    results,
    selectBoxValue,
    isFinished,
    isLoading,
    setSelectBoxValue,
    setIsFinished,
    setResults,
    setIsLoading,
  ] = useMysteryBoxStore(
    (state) => [
      state.results,
      state.selectBoxValue,
      state.isFinished,
      state.isLoading,
      state.setSelectBoxValue,
      state.setIsFinished,
      state.setResults,
      state.setIsLoading,
    ],
    shallow
  );

  const { data: boxes = [], isLoading: isBoxLoading } = useMysteryBoxList();

  useEffect(() => {
    if (boxes.length === 0) return;
    setResults(boxes);
  }, [boxes]);

  const handleOpenBox = async (boxes: MysteryBoxList[], number: number) => {
    // ** If loading is true will be returned
    if (isLoading) return;

    setIsLoading(true);
    const res = await openMysteryBox();
    const { status, msg, data } = res;
    const title = status ? "ทำรายการสำเร็จ" : "ทำรายการไม่สำเร็จ";

    if (!status) {
      enqueueSnackbar(msg, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      // ** Add delay for 2 seconds to prevent repeated pressing
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    const resultValue = parseInt(data);
    setResults(shuffleLuckyBoxResult(boxes, number, resultValue));
    setSelectBoxValue(resultValue);
    setTimeout(() => {
      refresh();
      setIsFinished();
      mutate("/api/v1/mystery-box/history");
    }, 3000);
    setTimeout(() => {
      setIsFinished();
      setIsLoading(false);
    }, 15000);
  };

  return (
    <Grid container columns={3} direction={"row"}>
      {isBoxLoading ? (
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      ) : (
        results.map((item, number) => {
          return (
            <Grid key={number} item xs={1}>
              <MysteryBoxItem
                key={number}
                selectBoxValue={selectBoxValue || 1}
                isFinished={isFinished}
                onFinished={() => {
                  handleOpenBox(boxes, number);
                }}
                isLoading={isLoading}
                {...item}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
}
