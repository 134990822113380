"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { green, grey, purple } from "@mui/material/colors";
import { Paper, Stack, useTheme, useMediaQuery, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, SwipeableDrawer } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import {
  AccountBalanceWallet,
  ArrowBack,
  CurrencyExchange,
  Notifications,
  Person4,
  Refresh,
  MenuOutlined,
  Inbox,
  Mail,
  ExitToApp,
  Lock,
  History,
  Bolt,
  PriceChange,
  Sync,
  HomeMaxOutlined,
  Home
} from "@mui/icons-material";
import { formatMoney } from "@/libs/common";
import { useRouter, usePathname } from "next/navigation";
import Image from "next/image";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfigStore } from "@/stores/configStore";
import numeral from "numeral";
import ThemedButton from "../StyledButton";
import useMe from "@/hooks/fetchers/useMe";
import { Logo } from "../logo";
import preset5 from "@/themes/presets/preset5";
import { Icon } from "@iconify/react";
import { useUserProfile } from "@/hooks";
import { LoadingButton } from "@mui/lab";
import { DepositAndWithdrawButtonGroup } from "@/sections";
import Link from "next/link";

const pages = ["กิจกรรม/ข่าวสาร", "โปรโมชั่น", "บทความ"];

type RightMenu = {
  key: string;
  label: string;
  variant: "text" | "outlined" | "contained";
  color:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
};

const rightMenus: RightMenu[] = [
  {
    key: "login",
    label: "เข้าสู่ระบบ",
    variant: "outlined",
    color: "success",
  },
  {
    key: "register",
    label: "สมัครสมาชิก",
    variant: "contained",
    color: "primary",
  },
];

const mainSettings = [
  // { key: "promotion", text: "กิจกรรม", icon: 'solar:bolt-bold' },
  // { key: "deposit", text: "เติมเงิน", icon: 'solar:wallet-2-bold' },
  // { key: "withdraw", text: "ถอนเงิน", icon: 'solar:money-bag-bold' },
  { key: "historyAll", text: "ประวัติธุรกรรม", icon: 'solar:history-2-bold' },
  { key: "mysteryBox", text: "กล่องสุ่ม", icon: 'solar:gift-bold' },
  { key: "wheel", text: "กงล้อ", icon: 'solar:ferris-wheel-bold' },
  { key: "refund", text: "คืนยอดเสีย", icon: 'solar:hand-money-bold' },
  { key: "Profile", text: "โปรไฟล์", icon: 'solar:card-2-bold' },
];

const subSettings = [
  { key: "changePassword", text: "เปลี่ยนรหัสผ่าน", icon: 'solar:lock-bold' },
  { key: "Logout", text: "ออกจากระบบ", icon: 'solar:exit-bold' },
];

function LayoutAppBar() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const isLoggedIn = user != null;
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { refresh: refreshMe } = useMe();
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  const router = useRouter();
  const pathname = usePathname();

  const showArrowBack = pathname !== "/";

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleArrowBack = () => {
    refreshMe();
    if (pathname.includes("/lobbies")) {
      router.push("/");
    } else {
      router.back();
    }
  };

  const handleRightMenuClick = (key: string) => {
    switch (key) {
      case "login":
        setOpenLoginModal(true);
        break;
      case "register":
        setAppDataModal("signUp");
        setOpenAppModal(true);
        break;
      default:
        break;
    }
  };

  const handleProfileMenuClick = (key: string) => {
    handleCloseUserMenu();
    if (key == "Logout") {
      logout();
      return
    }

    setOpen(true);
    setData(key);
    return
  };

  const current_balance = numeral(user?.balance).format("0,00.00") || 0;


  const [spin, setSpin] = React.useState(false);
  React.useEffect(() => {
    if (spin) {
      setTimeout(() => {
        setSpin(false);
      }, 750);
    }
  }, [spin]);

  const [scrollY, setScrollY] = React.useState({
    position: 0,
    direction: 'idle'
  });
  const onScroll = React.useCallback((event: any) => {
    // const { pageYOffset, scrollY } = window;
    // console.log("yOffset", pageYOffset, "scrollY", scrollY);
    const { scrollY } = window;
    setScrollY((prevState) => {
      return {
        position: scrollY,
        direction: prevState.position < scrollY ? 'down' : 'up'
      }
    });
  }, []);

  React.useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });
    // remove event on unmount to prevent a memory leak with the cleanup
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true } as any);
    }
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent<HTMLElement>, open: boolean) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    return setState({ ...state, left: open });
  }


  const list = (anchor: string) => (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
          my: 2
        }}
        role="presentation"
      >
        <Container>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                alt="Avatar"
                src={'/assets/icons/vip1.png'}
              />

              <Stack
                alignItems={"flex-start"}
                justifyContent={"center"}
                sx={{ ml: 2 }}
                spacing={0}
              >
                <Typography
                  variant="caption"
                  mb={-1.2}
                  color="white"
                  sx={{
                    ...(promotionHistory &&
                    {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile ? '90px' : '120px'
                    })
                  }}
                >
                  {
                    user?.fullname
                  }
                </Typography>
                <Typography variant="h6" fontWeight={500} color="white" sx={{
                  fontSize: 18,
                  fontWeight: 700,
                }}>
                  {current_balance}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon
                id="icon"
                icon={'material-symbols:close-rounded'}
                width={24}
                height={24}
                onClick={(e: any) => { toggleDrawer(e, false) }}
                style={{ cursor: 'pointer' }}
              />
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box
        flex={1}
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        }}
        role="presentation"
        onClick={(e: any) => { toggleDrawer(e, false) }}
        onKeyDown={(e: any) => { toggleDrawer(e, false) }}
      >
        <Divider />
        <List>
          {mainSettings
            .map((item, index) => (
              <ListItem
                key={item.text}
                disablePadding
                onClick={() => handleProfileMenuClick(item?.key)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon
                      id="icon"
                      icon={item.icon}
                      width={24}
                      height={24}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>

      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
          // mb: 2
        }}
        role="presentation"
        onClick={(e: any) => { toggleDrawer(e, false) }}
        onKeyDown={(e: any) => { toggleDrawer(e, false) }}
      >
        <Divider />
        <List>
          {subSettings
            .map((item, index) => (
              <ListItem
                key={item.text}
                disablePadding
                onClick={() => handleProfileMenuClick(item?.key)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon
                      id="icon"
                      icon={item.icon}
                      width={24}
                      height={24}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      variant="elevation"
      elevation={0}
      sx={{
        background: theme.gradient[400],
        ...((theme.name == 'preset5' || theme.name == 'preset23') && {
          // backgroundImage: `url(${theme.backgroundImage.body})`
          backgroundColor: "rgba(0,0,0,0.8)"
        }),
        ...(theme.name == 'preset16' && {
          boxShadow: '#0003 0 4px 6px -1px,#0000001f 0 2px 4px -1px'
        }),
        ...(theme.name == 'preset44' && {
          borderBottom: 'thin solid #1f1f2a',
        })
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            {/* {showArrowBack && (
              <IconButton
                style={{
                  border: "1.5px solid white",
                  height: "28px",
                  width: "28px",
                }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleArrowBack}
                color="inherit"
              >
                <ArrowBack style={{ fontSize: "18px" }} />
              </IconButton>
            )} */}

            {
              !isLoggedIn ? (
                <Box py={`${theme.config.logo.appBarSpacing}px`}>
                  <Logo
                    src={config?.logo || ""}
                    height={theme.config.logo.appBarHeight}
                  />
                </Box>
              ) : (
                <>
                  <IconButton
                    style={{
                      paddingTop: 0,
                      paddingLeft: 0
                    }}
                    aria-label="main menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(e: any) => { showArrowBack ? handleArrowBack() : toggleDrawer(e, true) }}
                    color="inherit"
                  >
                    {showArrowBack ? (<Home style={{ fontSize: "24px" }} />) : (<MenuOutlined style={{ fontSize: "24px" }} />)}
                  </IconButton>
                  <Box py={`${theme.config.logo.appBarSpacing}px`}>
                    <Logo
                      src={config?.logo || ""}
                      height={theme.config.logo.appBarHeight}
                    />
                  </Box>
                </>
              )
            }
          </Stack>
          <Box>
            {!isLoggedIn && (
              <Box display={"flex"}>
                <Avatar
                  component={Link}
                  href={config?.line_oa_url as any || ''}
                  target={"_blank"}
                  alt="line"
                  src={'/assets/icons/line.png'}
                  sx={{
                    width: '36px',
                    height: '36px',
                    mr: '8px',
                    mt: '-5px',
                    cursor: 'pointer'
                  }}
                />
              </Box>
            )}

            {isLoggedIn && (
              <Box display={"flex"}>
                {/* {
                  !isMobile && (
                    <>
                      <Box display="inline" mr={1}>
                        <Box display="flex">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('deposit')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:dollar-banknote"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ฝากเงิน
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('withdraw')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:money-bag"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ถอน
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )
                } */}
                {/* {
                  (promotionHistory && currentBalance <= 5) && (
                    <LoadingButton
                      style={{ borderRadius: 16, marginRight: '16px' }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleRemovePro()
                      }}
                      loading={isLoading}
                      sx={{
                        backgroundColor: (theme) => theme.name === 'preset14'
                          ? "#790e50"
                          : "default"
                      }}
                    >
                      ออกจากโปร
                    </LoadingButton>
                  )
                } */}

                <Tooltip title="Open settings">
                  <IconButton
                    onClick={() => {
                      refreshMe();
                      setSpin(true);
                    }}
                    sx={{
                      p: '0 8px',
                      mt: '-7px',
                      border: "1.5px solid rgba(255, 255, 255, 0.23)",
                      borderRadius: '16px'
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignItems={"flex-end"}
                        justifyContent={"center"}
                        sx={{ mr: 0.5 }}
                        spacing={0}
                      >
                        {/* <Typography
                          variant="caption"
                          mb={-1.2}
                          color="white"
                          sx={{
                            ...(promotionHistory &&
                            {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: isMobile ? '90px' : '120px'
                            })
                          }}
                        >
                          {
                            promotionHistory
                              ? isPromotionHistoryLoading
                                ? "กำลังโหลด"
                                : promotionHistory?.promotion.name
                              : "ยอดเงินคงเหลือ"
                          }
                        </Typography> */}
                        <Typography variant="h6" fontWeight={500} color="white" sx={{
                          fontSize: 18,
                          fontWeight: 700,
                        }}>
                          {current_balance}
                        </Typography>
                      </Stack>
                      <Icon
                        id="icon"
                        icon={
                          spin
                            ? 'line-md:loading-twotone-loop'
                            : 'line-md:confirm-circle-filled'
                        }
                        width={18}
                        height={18}
                      />
                      {/* <Avatar
                        alt="Remy Sharp"
                        src={
                          promotionHistory
                            ? "https://cdn-icons-png.freepik.com/512/12534/12534332.png"
                            : config?.admin_domain
                              ? config?.admin_domain
                              : "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=1800&t=st=1699245116~exp=1699245716~hmac=4ac516f9219aa13e9a3aa94d4425da97a75882dac9fc8edb557d84310e12047c"}
                      /> */}
                    </Stack>
                  </IconButton>
                </Tooltip>

                <Avatar
                  component={Link}
                  href={config?.line_oa_url as any || ''}
                  target={"_blank"}
                  alt="line"
                  src={'/assets/icons/line.png'}
                  sx={{
                    width: '36px',
                    height: '36px',
                    ml: 1,
                    mt: '-5px',
                    cursor: 'pointer'
                  }}
                />

                {/* <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting?.key}
                      onClick={() => handleProfileMenuClick(setting?.key)}
                    >
                      <Typography textAlign="center">
                        {setting?.text}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu> */}
              </Box>
            )}
          </Box>
        </Stack>
        {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
        {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".08rem",
              color: "primary.main",
              textDecoration: "none",
            }}
          >
            ทดสอบ
          </Typography> */}
      </Container>

      {scrollY.position >= 15 && (
        <Paper square sx={{
          pb: 1.5,
          background: theme.gradient[400],
          ...(theme.name == 'preset44' && {
            borderBottom: 'thin solid #1f1f2a',
          })
        }}>
          <Container maxWidth="xl">
            <DepositAndWithdrawButtonGroup />
          </Container>
        </Paper>
      )}

      <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={(e: any) => { toggleDrawer(e, false) }}
        onOpen={(e: any) => { toggleDrawer(e, true) }}
        PaperProps={{
          sx: {
            color: "#fff",
            backgroundColor: "#292838",
            backgroundImage: 'none'
          }
        }}
      >
        {list('left')}
      </SwipeableDrawer>

      {
        (scrollY.position >= 100 && scrollY.direction === 'up' && showArrowBack) && (
          <Box
            sx={{
              display: true ? 'flex' : 'none',
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
              position: "fixed",
              width: "48px",
              height: "48px",
              backgroundColor: "red",
              borderRadius: "12px",
              top: "unset",
              bottom: "0",
              right: "0",
              backgroundImage: "linear-gradient(0deg, #2f2f2f 0%, #4f4f4f 100%)",
              zIndex: 1000,
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              ...((!isLoggedIn && !isMdUp) && {
                marginBottom: '80px'
              }),
            }}
            onClick={() => window.scrollTo(0, 1)}
          >
            <Icon
              id="icon"
              icon={'solar:arrow-up-bold'}
              width={24}
              height={24}
            />
          </Box>
        )
      }
    </AppBar >
  );
}
export default LayoutAppBar;
