import { Icon } from "@iconify/react";
import { Box, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";
import React from "react";

const MotionGridItem = motion(Box as any);

type MysteryBoxItemProps = {
  key: number;
  value: number;
  message: string;
  isFinished: boolean;
  onFinished: () => void;
  selectBoxValue: number;
  isLoading: boolean;
};

export default function MysteryBoxItem({
  key,
  value,
  selectBoxValue,
  message,
  isFinished,
  onFinished,
  isLoading
}: MysteryBoxItemProps) {
  // ** จัดการการเปิดกล่องสุ่ม
  const handleOpenBox = () => {
    if (isFinished) return;
    onFinished();
  };

  return (
    <MotionGridItem
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: key * 0.2 }}
      className="mysteryBoxItem"
      onClick={handleOpenBox}
      position={"relative"}
    >
      <Box>
        <Box>
          {isFinished && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
            >
              {selectBoxValue == value ? (
                // <Typography
                //   textAlign={"center"}
                //   style={{ position: "absolute" }}
                // >
                //   {value == 0 && message}
                //   {value > 0 && `รับ ${value} เครดิต`}
                // </Typography>
                <Box position={"absolute"} width={"100%"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box
                      boxShadow={2}
                      bgcolor={'#191d23'}
                      style={{
                        borderRadius: "10px",
                        // width: "50px",
                        height: "24px",
                        padding: '0 8px'
                      }}
                    >
                      <Typography textAlign={"center"}>{`${value} เครดิต`}</Typography>
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <></>
                // <Box position={"absolute"} width={"100%"}>
                //   <Stack
                //     direction={"column"}
                //     alignItems={"center"}
                //     justifyContent={"center"}
                //   >
                //     {/* <Box as="i" className={"far fa-sad-cry"} /> */}
                //     <Box
                //       boxShadow={2}
                //       bgcolor={red[500]}
                //       style={{
                //         borderRadius: "10px",
                //         width: "50px",
                //         height: "24px",
                //       }}
                //     >
                //       <Typography textAlign={"center"}>{value}</Typography>
                //     </Box>
                //   </Stack>
                // </Box>
              )}
            </motion.div>
          )}
        </Box>
      </Box>
      {(isFinished) ? (
        <img
          style={{
            // padding: "5px",
            cursor: "pointer",
            objectFit: "contain",
          }}
          src="/assets/icons/unbox.png"
          width="100%"
          height="100%"
        />
      ) : (
        <>
          <Box>
            <img
              style={{
                padding: "5px",
                cursor: "pointer",
                objectFit: "contain",
                filter: isLoading ? "grayscale(90%)" : "grayscale(0%)"
              }}
              src="/assets/icons/box.png"
              width="100%"
              height="100%"
            />
          </Box>
        </>
      )}
    </MotionGridItem>
  );
}
