import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

export interface MysteryBoxList {
	id: number;
	value: number;
	text: string;
	isWin?: boolean;
	message: string;
}

type MysteryBoxListReturn = {
  data: MysteryBoxList[];
  isLoading: boolean;
  isError: any;
  sum_all: number;
  have_mysterybox_log: boolean;
};

export default function useMysteryBoxList(): MysteryBoxListReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<MysteryBoxListReturn>(
    isLoggedIn ? apiPath.mysteryBox.mysteryBoxList : null,
    fetcherAuth
  );
  const boxes = data?.data || [];
  const sum_all = data?.sum_all || 0;
  const have_mysterybox_log = data?.have_mysterybox_log || false;

  return {
    data: boxes,
    isLoading: !error && !data,
    isError: error,
    sum_all,
    have_mysterybox_log
  };
}
