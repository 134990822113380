"use client";
import * as React from "react";
import Image from "next/image";
import {
  useTheme,
  Box,
  Divider,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Chip,
  Grid,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { shallow } from "zustand/shallow";
import { useSnackbar } from "notistack";
import useWheelList from "@/hooks/fetchers/useWheelList";
import useWheelHistory from "@/hooks/fetchers/useWheelHistory";
import Spinner from "./Spinner";
import { useConfigStore } from "@/stores/configStore";
import numeral from "numeral";
import { useAuthStore } from "@/stores/authStore";
import TermsDialog from "@/sections/lucky-wheel/TermsDialog";
import { Info } from "@mui/icons-material";

const TYPES: {
  [key: string]: string;
} = {
  wheel_ticket: "ตั๋ว",
  wheel_credit: "ซื้อ",
};

const WheelWinlose = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { data: wheels, isLoading: isLoadingWheel, refresh: refreshWheel } = useWheelList();
  const { data: histories, refresh, isLoading: isLoadingList } = useWheelHistory();
  const { enqueueSnackbar } = useSnackbar();
  const segments: any = wheels?.data;
  const winningSegment = segments?.find((o: any) => o?.value === 5000);
  const [congratulation, setCongratulation] = React.useState("");

  function createData(total: string, name: string, calories: string) {
    return { total, name, calories };
  }

  const segColors: string[] = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
    "#0F1C40",
    "#0E76EA",
  ];

  const closeCongratulation = () => {
    setCongratulation("");
  };

  const onFinished = (winner: any) => {
    refreshWheel();
    // refresh();
    setCongratulation(winner.message);
    enqueueSnackbar(winner.message, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  if (isLoadingWheel && isLoadingList) {
    return (
      <>
        <Grid sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  }

  const rows = [
    createData(
      numeral(wheels?.sum_total).format("0,0.00"),
      wheels?.wheel_type === 'deposit'
        ? wheels?.wheel_max_deposit || "0"
        : wheels?.wheel_type === 'winlose'
          ? wheels?.wheel_max_winlose || "0"
          : wheels?.wheel_max_ticket_tunover || "0",
      "1"),
    // createData("ราคาซื้อ", "จำนวน"),
    // createData(config?.wheel_buy_credit || "0", "1 ครั้ง"),
  ];

  return (
    <Box width={'100%'}>
      {/* <Box>
        <Divider>
          <Chip
            label="โบนัสล้อนำโชค"
            color="primary"
            size="medium"
            style={{ fontSize: "18px", fontWeight: 700 }}
          />
        </Divider>
      </Box> */}

      {/* <Box
        mt={2}
        flex="1"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/828x274_wheel.jpg`}
          width={isMobile ? 300 : 400}
          height={isMobile ? 93.75 : 125}
          alt={'cashback'}
          style={{
            borderRadius: '16px',
            width: '100%',
            height: 'auto'
          }}
        />
      </Box> */}

      <Box display="flex" flexDirection={"row"} alignItems={"start"} justifyContent={'space-between'} mt={3}>
        <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
          {`จำนวนตั๋วปัจจุบัน: `}
          <span style={{ color: user?.wheel_ticket ? '#3DFB35' : '#fdbd00' }}>{`${user?.wheel_ticket ? 1 : 0}`}</span>
        </Typography>
        {/* <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }} onClick={() => { setDialogOpen(true) }}>
          <span style={{ color: '#fdbd00', cursor: 'pointer' }}>รายละเอียด</span>
        </Typography> */}
      </Box>
      <Box display="flex" flexDirection={"column"} alignItems={"start"} mt={1}>
        <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
          {`ยอดเล่นเพื่อแลกตั๋ว: `}
          <span
            style={{ color: wheels?.sum_total >= parseFloat(wheels?.wheel_max_ticket_tunover) ? '#3DFB35' : '#fdbd00' }}
          >
            {`${wheels?.sum_total ? numeral(wheels?.sum_total).format("0,0.00") : '0'}`}
          </span>
          <span>{`/${wheels?.wheel_max_ticket_tunover ? numeral(wheels?.wheel_max_ticket_tunover).format("0,0.00") : '0'}`}</span>
        </Typography>
      </Box>
      {/* <Box display="flex" flexDirection={"column"} alignItems={"start"} mt={1}>
        <Typography variant="subtitle2" lineHeight={1.2} align="left" sx={{ color: (theme) => theme.gradient[920] }}>
          {`ยอดเล่นเพื่ออัพเกรด: `}
          {
            wheels?.current_step?.index !== wheels?.step_all?.length - 1 && (
              <span
                style={{
                  color: (wheels?.sum_total > parseFloat(wheels?.current_step?.last_step) + 0.01 || wheels?.current_step?.index === wheels?.step_all?.length - 1)
                    ? '#3DFB35'
                    : '#fdbd00'
                }}
              >
                {`${wheels?.sum_total ? numeral(wheels?.sum_total).format("0,0.00") : '0'}`}
              </span>
            )
          }
          <span>{`${wheels?.current_step?.last_step
            ? wheels?.current_step?.index === wheels?.step_all?.length - 1
              ? '-'
              : `/${numeral(parseFloat(wheels?.current_step?.last_step) + 0.01).format("0,0.00")}`
            : '/0'
            }`}</span>
        </Typography>
      </Box> */}
      <Box mt={2}>
        <Divider sx={{ background: '#213743', borderWidth: '1px' }} />
      </Box>

      <Box sx={{ position: "relative", overflow: "hidden" }} width="100%">
        {congratulation && (
          <Box
            onClick={closeCongratulation}
            sx={{
              borderRadius: 2,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              pt: 4,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <Typography
                  variant="h5"
                  fontSize={18}
                  fontWeight={"bold"}
                  color="black"
                  align="center"
                  mt={4}
                  ml={5}
                >
                  {congratulation}
                </Typography>
              </Box>
              <img
                src="/assets/card-cong.avif"
                alt={congratulation}
                width="100%"
              />
            </Box>
          </Box>
        )}

        <Box mt={2}>
          {segments ? (
            <Spinner
              segments={segments || []}
              segColors={segColors || []}
              winningSegment={winningSegment}
              onFinished={(winner: any) => onFinished(winner)}
              primaryColor={"#1A2027"}
              contrastColor="white"
              buttonText={""}
              isOnlyOnce={false}
              size={148}
              upDuration={100}
              downDuration={600}
              fontFamily={'"Noto Sans Thai", sans-serif'}
              config={config}
              wheels={wheels}
            />
          ) : (
            <Box
              p={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>

      <Box mt={2}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={(theme) => theme.gradient[900]}>ประวัติการรับโบนัส</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} aria-label="simple table">
                <TableHead>
                  {/* <TableRow>
                    <TableCell colSpan={2} align="center" sx={{ color: (theme) => theme.gradient[900] }}>
                      <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                        {`บันทึกของฉัน`}
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                </TableHead>
                <TableBody>
                  {histories
                    ? histories.slice(0, 5).map((row: any) => (
                      <TableRow
                        key={row.created_at}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                          {row.created_at}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#fdbd00' }}>{row.coin}</TableCell>
                      </TableRow>
                    ))
                    : histories}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* <Box mt={2}>
        <Alert icon={<Info fontSize="inherit" />} variant="outlined" severity="warning" sx={{
          backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#1a2c38"
          background: 'linear-gradient(-325deg, rgba(250, 173, 20, 0.15) 100%, rgba(250, 173, 20, 0))'
        }}>
          <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            {`เงื่อนไขกิจกรรม “โบนัสล้อนำโชค”`}
          </AlertTitle>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - ลูกค้าจะต้องไม่กดรับ โบนัส ทุกประเภท
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            {`- ทุกๆ การเดิมพันจะได้รับ 1 ยอดเล่นโดยอัตโนมัติ หากไม่กดรับสิทธิ์ภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`}
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - ขอสงวนสิทธิ์การหมุน 1 ครั้ง/1 วัน เมื่อทำการหมุนแล้ว จะไม่สามารถหมุนต่อได้จนถึงวันถัดไป
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            {`- ในช่วงระยะเวลากิจกรรม ทุกวันๆ การเดิมพันจะได้รับ 1 ยอดเล่นโดยอัตโนมัติ ยอดเล่นอย่างน้อย 1,500 จะได้รับตั๋วสำหรับหมุนกงล้อ 1 ครั้ง รางวัลสูงสุดคือ 5,888`}
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            {`- สะสมยอดเล่น “${'โบนัสล้อนำโชค'}” ของยอดการเดิมพันได้ทุกวัน โดยระบบจะทำการคำนวน${'ยอดเล่น'}ทุกวันตั้งแต่เวลา 00:00 น. เป็นเวลา 24 ชั่วโมง หากไม่กดรับสิทธิ์ภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`}
          </Typography>
        </Alert>
      </Box> */}

      <TermsDialog
        wheelData={null}
        open={dialogOpen}
        closeDialog={() => { setDialogOpen(false) }}
      />
    </Box>
  );
};

export default WheelWinlose;