"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { Divider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Avatar, Chip, Stack, Typography } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  const theme = useTheme();
  const [user] = useAuthStore(
    (state) => [state.user!],
    shallow
  );

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={3}>
          <Item sx={{ color: (theme) => theme.gradient[700] || "white" }}>
            เว็บ api ตรง โปรโมชั่น และ เครดิตฟรี คืนยอดเสีย คลิกเลย เว็บ api ตรง
            สมัยนี้หาได้ยากมากจริงๆ ไม่ผ่านเอเย่นต์ วิธีสังเกต ต้องมีการ
            คืนยอดเสีย ให้กับลูกค้า
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item>
            <Stack
              sx={{
                alignItems: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Typography
                mb={1}
                variant="h5"
                color={(theme) =>
                  theme.gradient[700] ||
                  theme.palette.primary.main
                }
              >
                TAGS
              </Typography>
              <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                <Chip label="เว็บตรง" sx={{ color: (theme) => theme.gradient[920] }} />
                <Chip label="โปรโมชั่น" sx={{ color: (theme) => theme.gradient[920] }} />
                <Chip label="เครดิตฟรี" sx={{ color: (theme) => theme.gradient[920] }} />
                <Chip label="คืนยอดเสีย" sx={{ color: (theme) => theme.gradient[920] }} />
                <Chip label="แนะนำเพื่อน" sx={{ color: (theme) => theme.gradient[920] }} />
              </Stack>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={12} md={5}>
          <Item>
            <Stack
              sx={{
                alignItems: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Typography
                mb={1}
                variant="h5"
                color={(theme) => theme.gradient[700] || theme.palette.primary.main}
              >
                ธนาคารที่รองรับ
              </Typography>
              <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KBANK.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/SCB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KTB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BAY.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TRUEWALLET.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/GSB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BBL.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BAAC.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KKB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/OSK.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TTB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TISGO.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/UOB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/CITI.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/LNH.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/CIMB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TCR.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/MIZUHO.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/SCBT.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/ICBC.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/ISBT.png?auto=compress&q=50&fm=webp"
                />
              </Stack>
            </Stack>
          </Item>
        </Grid> */}

        <Grid item xs={12}>
          <Divider sx={{ background: 'rgba(255, 255, 255, 0.23)', borderWidth: '1px' }} />
        </Grid>

        <Grid item xs={12}>
          <Item sx={{ color: (theme) => theme.gradient[700] || 'rgba(255, 255, 255, 0.5)', p: 0 }}>
            © 2025 zing4.com All rights reserved.
          </Item>
          <Item sx={{ color: (theme) => theme.gradient[700] || 'rgba(255, 255, 255, 0.5)', p: 0 }}>
            Version v2.2371.7
          </Item>
        </Grid>

        <Grid item xs={12} sx={{ mb: user ? [1, 1, 1] : [7, 7, 1] }}>
        </Grid>
      </Grid>
    </Box>
  );
}
